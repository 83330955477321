@use 'sass:map';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

//noinspection CssUnknownTarget
@import 'bootstrap/scss/bootstrap';

@import 'select2/src/scss/core';
$s2bs5-border-color: $border-color;
@import '~select2-bootstrap-5-theme/src/include-all';

// Custom styles

[x-cloak] {
    display: none !important;
}

a.disabled,
a[disabled] {
    color: $text-muted;
    text-decoration: none;
    pointer-events: none;
    cursor: not-allowed;
}

table a[href=""] {
    pointer-events: none;
    color: inherit;
    text-decoration: none;
    cursor: none;
}

nav {
    a:hover {
        color: $teal-700;
    }
}

main {
    padding-bottom: 1px;
}

footer {
    background: $white;
    padding: map.get($spacers, 3) map.get($spacers, 4);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer a {
    text-decoration: none;
    font-weight: bold;
    color: inherit;

    &:hover {
        color: inherit;
    }
}

table {
    details {
        text-align: center;

        > summary {
            list-style-type: none;

            &::-webkit-details-marker,
            &::marker {
                display: none;
            }
        }

        &[open] {
            > summary {
                margin-bottom: 0.5rem;
            }
        }
    }

    dl {
        margin: 0;

        dd {
            margin-bottom: 0;
        }

        &:not(.list-unstyled) {
            > div {
                margin-bottom: 0.5rem;

                &:not(:last-of-type) {
                    margin-bottom: 0;
                }
            }

            dt, dd {
                display: inline-block;
            }

            dt {
                &:after,
                &::after {
                    content: ":";
                }
            }
        }
    }
}

#user-notes-container {
    width: 35vw;

    #user-notes-field {
        min-height: 15vh;
        max-height: 80vh;
        height: 40vh;
    }
}

.consumption-timeslots {
    display: inline-block;
    list-style: none;

    & li {
        display: inline-block;
    }
}

.cursor-help {
    cursor: help;
}

.double-level-th-heading {
    border-bottom-color: $gray-500;
}

.double-level-th {
    border-right: 1px solid $gray-500;
}

.double-level-th:last-child {
    border-right: none;
}

.show-form-details {
    margin-top: map.get($spacers, 4);
    padding-top: map.get($spacers, 3);
    border-top: 1px solid $gray-300;
    color: $text-muted;
}

.help-tooltip:hover {
    @extend .cursor-help;
    @extend .user-select-none;
}

.required:after,
.required::after {
    content: '*';
    font-weight: bold;
    color: $red;
}

.select2-selection--multiple .select2-search--inline,
.select2-selection--multiple .select2-search__field {
    width: calc(100% - 1rem) !important;
}
